import React, { useContext } from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

import * as styles from "./MobilePages/utils/mobilePages.module.scss"

import FooterLink from "./FooterLink"
import icons from "./utils/downloadablesIcons.json"
import { AppContext } from "../context/AppContext"

const DownloadablesCardMobile = ({ downloadable }) => {
    let { state } = useContext(AppContext)
    return (
        <div className={classNames(styles["downloadables__card"], "m-3 mb-5")}>
            <div className={classNames(styles["downloadables__tag"], "mb-5",
                `${downloadable.type === "Stickers" ? styles["sticker"] : ""}`,
                `${downloadable.type === "Wallpaper" ? styles["wallpaper"] : ""}`,
                )}>
                    {downloadable.type}
            </div>
            <article className="media is-align-items-center">
                <figure className="media-left">
                    <img
                        src={downloadable?.thumbnail}
                        alt="thumbnail"
                        className="image is-96x96"
                        style={{borderRadius: "15px"}}
                        draggable={false}
                    />
                </figure>
                <div className="media-content">
                    <div className="is-flex is-flex-direction-row">
                        <div className={classNames(styles["downloadables__contentHeader"],
                            "mr-3")}>
                            {downloadable.name}
                        </div>
                    </div>
                    <div className={classNames(styles["downloadables__contentSubtitle"])}
                        dangerouslySetInnerHTML={{
                            __html: downloadable.description
                        }}
                    />
                </div>
            </article>
            
            <ReactTooltip/>
            <div className="pt-2 is-flex is-flex-wrap-wrap is-justify-content-center">
                {Object.keys(downloadable.links).map((link) => (
                    <p key={link} data-tip={link}>
                        <FooterLink
                            image={{
                                src: state.isDark ?  icons[`${link}_dark`] : icons[link],
                                alt: icons[link]
                            }}
                            link={downloadable.links[link]}
                        />
                    </p>
                ))}
            </div>
        </div>
    )
}

export default DownloadablesCardMobile