import React, { useState } from "react"
import classNames from "classnames"
import useSound from "use-sound"

import downloadables from "../Windows/utils/downloadables.json"
import DownloadablesCardMobile from "../DownloadablesCardMobile"
import MobileWindow from "./MobileWindow"

import * as styles from "./utils/mobilePages.module.scss"
import clickSfx from "../../../static/sounds/click_filter.mp3"

const DownloadsPage = () => {
    const [filter, setFilter] = useState("none");
    const [playClickSfx] = useSound(clickSfx, {volume: 0.5})

    const filterItems = (filter) => {
        if (filter === "none") return downloadables
        return downloadables.filter((downloadable) => downloadable.type === filter);
    }

    let filteredDownloadables = filterItems(filter);

    return (
        <MobileWindow title="downloads">
            <div className={classNames("p-4", styles["downloadables__content"])}>
                <div className="has-background-light p-5 mb-5 is-size-6">
                    The following are some downloadable content you can grab from me! :)<br/>
                    All content is <b>free for personal use</b> only.
                </div>

                <div className="is-flex is-align-items-center pb-3 pl-3">
                    <p className="pr-2">Filter by: </p>
                    <button className={classNames(styles["downloadables__tag"],
                        styles["sticker"], styles["button"])}
                        onClick={() => setFilter("Stickers")}
                        onMouseUp={() => playClickSfx()}>
                        Stickers
                    </button>
                    <button className={classNames(styles["downloadables__tag"],
                        styles["wallpaper"], styles["button"])}
                        onClick={() => setFilter("Wallpaper")}
                        onMouseUp={() => playClickSfx()}>
                        Wallpaper
                    </button>
                    {filter !== "none" ? 
                        <button className={classNames(styles["downloadables__tag"],
                            styles["remove"], styles["button"])}
                            onClick={() => setFilter("none")}
                            onMouseUp={() => playClickSfx()}>
                            X
                        </button>
                        : null
                    }
                </div>

                {filteredDownloadables.map((downloadable) => (
                    <DownloadablesCardMobile
                        key={downloadable.name}
                        downloadable={downloadable}
                    />
                ))}
            </div>
        </MobileWindow>
    )
}
export default DownloadsPage